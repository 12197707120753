// //for IE, Edge polyfill
import smoothscroll from 'smoothscroll-polyfill';
import objectFitImages from 'object-fit-images';
import ScrollOut from "scroll-out";

smoothscroll.polyfill();
objectFitImages();

//load web fonts
//import './_fonts.js';

window.addEventListener('load', function(){
	deSVG('.front-page .site-header__tel', true);
});

function setScrollOut(){

  ScrollOut();

  ScrollOut({
    targets: ".site-header",
    offset: 400
  });

  ScrollOut({
    targets: "main",
    onShown: function(el) {
      document.body.classList.remove("main-end");
    },
    onHidden: function(el) {
      document.body.classList.add("main-end");
    }
  });

}
setScrollOut();
